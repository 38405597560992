export const auth = [
  {
    path: '/',
    name: 'Auth',
    meta: {
      title: 'POLSKA DOMENA KRAJOWA (1996-2001)'
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/PleaseAuth.vue')
  }
]