import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import {routes} from './router/routes'
import {auth} from './router/auth'
import store from './store'
import Axios from 'axios'
import {setServerConfigInApp} from './configs/configHelper'
// import { casAuth } from './utils/casAuth'

import './assets/styles/main.scss'

Vue.config.productionTip = false

Axios.get('services/frontend/properties/solrwaybackweb/').then(response => {
	setServerConfigInApp(response.data)
	initializeVue(response.data['webapp.baseurl'])
}).catch(error => initializeVue('/'))

function initializeVue(appBaseURL) {

	const router = new VueRouter({mode: 'history', base: appBaseURL})

	Axios.get('https://testautoryzacja.ckc.uw.edu.pl/auth/', {cache: false}).then(response => {
		console.log(response)
		if (typeof response.data.user !== 'undefined') {
			router.addRoutes(routes)
		} else {
			router.addRoutes(auth)
		}
		Vue.use(VueRouter)

		new Vue({
			router,
			store,
			render: h => h(App)
		}).$mount('#app')

	}).catch(error => {
		console.log(error)

    router.addRoutes(auth)

    Vue.use(VueRouter)

		new Vue({
			router,
			store,
			render: h => h(App)
		}).$mount('#app')

	})


}
